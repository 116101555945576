#Tampa_voucher_page_image {
    background-image: url(../../assets/default/pages/vouchersPage/gift.webp);
}

.city_voucher_title {
    margin-top: 1.7rem;
    font-weight: 600;
}

.voucher_page_image {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
}

.vouchers_stores_sections:not(:first-child) {
    margin-bottom: 4rem;
}

.vouchers_title_page {
    font-weight: 600;
    font-size: 1.85rem;
    margin-top: 1%;
}

@media (min-width: 992px) {
    .voucher_page_image {
        height: 50vh;
    }
}

@media (max-width: 992px) {
    .voucher_page_image {
        height: 30vh;
    }
}
