#room-section {
    position: relative;
    padding: 3% 8%;
    height: auto;
    margin-left: 0;
}

@media screen and (max-width: 768px) {
    #room-section {
        height: auto;
        padding: 10% 3%;
    }
}

@media screen and (min-width: 769px) and (max-width: 1200px) {
    #room-section {
        padding: 8%;
    }
}
