/*!
 * Start Bootstrap - Grayscale v4.0.0-beta.2 (https://startbootstrap.com/template-overviews/grayscale)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-grayscale/blob/master/LICENSE)
 */

body {
    font-family: "Raleway", sans-serif;
    position: relative;
    font-weight: 50;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #000000;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}

h1,
h2,
h3,
h6 {
    font-family: var(--font-family-sans-serif) !important;
    font-weight: 900;
    margin: 0 0 35px;
    text-transform: uppercase;
}

p {
    font-size: 16px;
    font-weight: 100;
    line-height: 1.5;
    margin: 0 0 25px;
}

h1 {
    font-weight: 900;
    font-size: 65px;
    color: white;
}

.underline {
    padding-bottom: 10px;
    border-bottom: 5px solid #da1a35;
}
.nav-link {
    display: inline;
}
#navbarDropdownMenuLink .nav-link {
    display: block;
}

@media (min-width: 768px) {
    p {
        font-size: 18px;
        font-weight: 100;
        line-height: 1.3;
        margin: 0 0 35px;
    }
}
a {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #da1a35;
}

a:focus,
a:hover {
    text-decoration: none;
    color: #fff;
}
div#navbarResponsive {
    text-align: right;
}

@media (max-width: 600px) {
    #difficulty {
        margin-top: 40px;
    }
}

#difficulty {
    margin-top: 20px;
}

#companies {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;
    /* background-image: url("../img/companies.jpg");*/
}

@media (max-width: 992px) {
    .carousel {
        padding-top: 63px;
    }
}

@media (max-width: 450px) {
    .carousel {
        padding-top: 28px;
    }
}
@media (max-width: 375px) {
    .carousel {
        padding-top: 3px;
        margin-bottom: 3%;
    }
}

.counter {
    font-size: 1.9rem;
    color: #da1a35;
    font-weight: 300;
    margin-bottom: 10px;
    display: inline-block;
}

@media (max-width: 990px) {
    #mainNav {
        font-family: "Raleway", sans-serif;
        margin-bottom: 0;
        text-transform: uppercase;
        background-color: #0e0e0e;
        font-weight: 500;
    }
}

.content-section {
    padding: 9% 0%;
}

@media (min-width: 992px) {
    .content-section {
        padding: 4% 0%;
    }
}

.btn {
    font-family: Cabin, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    border-radius: 0;
}

.btn-default {
    color: #da1a35;
    border: 1px solid #da1a35;
    background-color: transparent;
}

.btn-default:focus,
.btn-default:hover {
    color: #000;
    border: 1px solid #da1a35;
    outline: 0;
    background-color: #da1a35;
}

@media (max-width: 530px) {
    h1 {
        font-size: 40px;
    }
}

footer {
    padding: 50px 0;
}

footer p {
    font-size: 14px;
    margin: 0;
}

::-moz-selection {
    background: #fcfcfc;
    background: rgba(255, 255, 255, 0.2);
    text-shadow: none;
}

::selection {
    background: #fcfcfc;
    background: rgba(255, 255, 255, 0.2);
    text-shadow: none;
}

img::selection {
    background: 0 0;
}

img::-moz-selection {
    background: 0 0;
}

/* Since positioning the image, we need to help out the caption */

@media (max-width: 991px) {
    .display-items,
    .display-items-desktop {
        display: none !important;
    }
    .display-items-mobile {
        display: block !important;
    }
    .counter {
        font-size: 26px;
        color: #da1a35;
        font-weight: 300;
        margin-bottom: 10px;
        display: inline-block;
    }
}

#book_now_imgr {
    width: 200px;
}

@media (max-width: 500px) {
    #book_now_imgr {
        width: 150px;
    }
    .counter {
        font-size: 22px;
        color: #da1a35;
        font-weight: 300;
        margin-bottom: 10px;
        display: inline-block;
    }
    .underline {
        padding-bottom: 0px;
        border-bottom: 5px solid #da1a35;
    }
}

@media (min-width: 500px) {
    #book_now_imgr {
        width: 200px;
    }
}

.display-items-mobile {
    display: none;
}
/*
#location {
  margin-bottom: 1%;
}*/

@media (min-width: 991px) {
    #titles {
        width: 200px;
    }
}
@media (max-width: 991px) {
    #titles {
        height: 100px;
    }
}

@media (max-width: 991px) {
    #titles {
        height: 100px;
    }
}
#loading {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: url('../img/spinner.gif') no-repeat center center;*/
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (min-width: 1064px) {
}

.st1,
.st2 {
    display: inline;
}

.st6 {
    fill: #fff;
}

.st8 {
    font-size: 19.3px;
}

#client {
    transform: translate(-866px);
}

#clients {
    transform: translate(-866px, -220px);
}

#FRANCE path {
    fill: #b32d36;
}

#AUSTRALIA:hover path,
#AUSTRALIA:hover rect,
#DENMARK:hover path,
#DENMARK:hover rect,
#FRANCE:hover path,
#FRANCE:hover rect,
#GERMANY:hover path,
#GERMANY:hover rect,
#GREECE:active path,
#GREECE:active rect,
#GREECE:focus path,
#GREECE:focus rect,
#GREECE:hover path,
#GREECE:hover rect,
#ITALY:hover path,
#ITALY:hover rect,
#NETHERLANDS:hover path,
#PORTUGAL:hover path,
#PORTUGAL:hover rect,
#SWITZERLAND:hover path,
#SWITZERLAND:hover rect,
#UK:hover path,
#UK:hover rect,
#KUWAIT:hover path,
#KUWAIT:hover polygon,
#SAUDI_ARABIA:hover polygon {
    fill: #b32d36 !important;
}

@media (max-width: 1060px) {
    #AUSTRALIA:hover path,
    #AUSTRALIA:hover rect,
    #DENMARK:hover path,
    #DENMARK:hover rect,
    #FRANCE:hover path,
    #FRANCE:hover rect,
    #GERMANY:hover path,
    #GERMANY:hover rect,
    #GREECE:active path,
    #GREECE:active rect,
    #GREECE:focus path,
    #GREECE:focus rect,
    #GREECE:hover path,
    #GREECE:hover rect,
    #ITALY:hover path,
    #ITALY:hover rect,
    #NETHERLANDS:hover path,
    #PORTUGAL:hover path,
    #PORTUGAL:hover rect,
    #SWITZERLAND:hover path,
    #SWITZERLAND:hover rect,
    #UK:hover path,
    #UK:hover rect,
    #KUWAIT:hover path,
    #KUWAIT:hover polygon,
    #SAUDI_ARABIA:hover polygon {
        fill: #b32d36;
    }
}
#svg-mobile {
    display: none;
}
@media screen and (max-width: 1060px) {
    #svg-mobile {
        display: block;
        pointer-events: none;
    }
}

.second-box {
    z-index: 10;
    flex-wrap: nowrap;
}

.second-box img {
    margin: 1rem auto;
}
