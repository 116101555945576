#footer {
    margin-bottom: 3%;
}

.footer-p {
    font-size: 0.85rem;
}

.second {
    margin-top: -7%;
}

@media (min-width: 420px) and (max-width: 992px) {
    .second {
        margin-top: -4%;
    }
}
