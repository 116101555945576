#mainNav {
    font-family: "Raleway", sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030;
}

.image-lang-dropdown {
    width: 1.3rem;
}

.custom-btn-class {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    margin-top: -6%;
    border-radius: 0.25rem;
    transition: background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-dropdown-show .custom-dropdown-menu {
    display: none;
}

.custom-dropdown-show .custom-dropdown-menu.show {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 7%;
    z-index: 1000;
    font-size: 1rem;
    text-align: left;
}

.custom-dropdown-show,
.custom-dropdown-menu.show:hover {
    cursor: pointer;
}

#logo {
    width: 65px;
}

@media (min-width: 2000px) {
    #nav-links {
        font-size: 1.1rem;
    }
}

@keyframes slide-left {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-60%);
    }
}

@media (max-width: 600px) {
    #mobileDropdownMenuLink {
        margin-left: 2.5%;
    }
}

@media (min-width: 600px) and (max-width: 992px) {
    #mobile-lang-links {
        margin-left: 8.5%;
        animation: slide-left 0.2s ease-in both;
        padding-right: 2%;
    }
}

#mainNav:hover {
    background-color: transparent;
    outline: 0px;
}

#mainNav:active {
    background-color: transparent;
    outline: 0px;
}

#mainNav:focus {
    background-color: transparent;
    outline: 0px;
}

#lang-links {
    background-color: transparent;
    padding-top: 20%;
}

@media (min-width: 600px) and (max-width: 767px) {
    #logo {
        margin: 15% 0% 0% 25% !important;
    }
    #toggling-btn {
        margin-left: 70%;
    }
    #mainNav .navbar-toggler {
        margin-left: 70%;
        padding-left: 5%;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    #logo {
        margin: 15% 0% 0% 35% !important;
    }

    #toggling-btn {
        margin: 0% 2% 0% 0%;
    }

    .remove {
        display: none !important;
    }
}

.dropdownMenuLink {
    display: flex;
}

@media (max-width: 767px) {
    #lang-links {
        background-color: transparent;
        padding-top: 3%;
    }
}

@media (max-width: 405px) {
    .navbar-collapse.collapse {
        margin-left: -4.5%;
        margin-right: -4.5%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .navbar-collapse {
        box-shadow: inset 0 1px transparent;
    }

    #logo {
        margin: 0% 0% 0% 20%;
    }
}

@media (max-width: 599px) {
    #logo {
        margin: 10% -15% 0% 0%;
        padding: 16% 0% 0% 0%;
        width: 47px;
    }

    .fa-bars {
        font-size: 1rem;
    }

    #mainNav .navbar-nav .nav-item {
        margin: -1% 2% 1% 0%;
    }

    #lang-links {
        background-color: transparent;
        right: 69.7%;
    }

    #mainNav {
        all: none;
    }
}

@media (max-width: 311px) {
    #logo {
        margin: 15% 0% 0% 0%;
    }

    #toggling-btn {
        margin: 0% 0% 0% 35%;
    }
}

.nav-item {
    padding-right: 10px;
    padding-bottom: 10px;
}

@media (min-width: 992px) and (max-width: 1200px) {
    #links-list {
        margin-left: auto;
    }
}

@media (min-width: 767px) and (max-width: 992px) {
    .custom-lang-text-btn {
        margin-right: 6.5%;
    }
    #mobile-lang-links {
        margin-left: 1%;
        animation: slide-left 0.2s ease-in both;
    }
}

@media (max-width: 767px) {
    .custom-lang-text-btn {
        margin-right: 3%;
    }
    #mobile-lang-links {
        margin-left: 5.5%;
        animation: slide-left 0.2s ease-in both;
    }
}

@media (max-width: 992px) {
    #links-list {
        margin: 0;
    }
    .navbar-collapse {
        margin: 0 -5%;
    }
    .custom-collapsed {
        display: none;
    }
    .navbar-collapse {
        background-color: black;
    }
    .toronto-menu,
    .Perugia-menu,
    .Málaga-menu,
    .Jeddah-menu,
    .toronto-text-lang-dropdown {
        font-size: 0.65rem;
    }

    .Tampa-menu {
        font-size: 0.8rem;
    }
    .text-lang-dropdown {
        font-size: 0.65rem;
        display: block;
    }
    .image-lang-dropdown {
        width: 1rem;
    }
}
.Dhabi-menu {
    font-size: 0.65rem;
}

@media (max-width: 320px) {
    .navbar-collapse {
        margin: 0 -10%;
    }
}

.toronto-menu,
.toronto-text-lang-dropdown {
    font-size: 0.77rem;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row;
        margin-left: auto !important;
    }
    .toronto-menu,
    .toronto-text-lang-dropdown {
        font-size: 0.77rem;
    }

    .Jeddah-menu,
    .Málaga-menu,
    .Perugia-menu {
        font-size: 0.791rem;
    }

    .Tampa-menu {
        font-size: 0.8rem;
    }

    .nav-item {
        padding-right: 6px;
        padding-bottom: 0px;
    }
    .navbar-collapse {
        box-shadow: inset 0 1px transparent;
    }
}

@media (min-width: 2000px) {
    .toronto-text-lang-dropdown {
        font-size: 1.1rem;
    }
    .custom-btn-class {
        margin-top: 6%;
    }
    .image-lang-dropdown {
        width: 1.7rem;
    }
}
