/*!

 * Start Bootstrap - Grayscale v4.0.0-beta.2 (https://startbootstrap.com/template-overviews/grayscale)

 * Copyright 2013-2017 Start Bootstrap

 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-grayscale/blob/master/LICENSE)

 */

@font-face {
    font-family: proxima-nova;

    /*  src: url("../vendor/proxima_ssv/proxima-nova-regular.otf")*/
}

.image-info-container {
    width: 100%;

    height: 100%;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;

    -webkit-box-pack: center;

    -ms-flex-pack: center;

    justify-content: center;

    -webkit-box-align: center;

    -ms-flex-align: center;

    align-items: center;

    position: absolute;

    top: 0;

    left: 0;
}

#Scenarios a {
    -webkit-transition: all 0.2s ease-in-out;

    -o-transition: all 0.2s ease-in-out;

    transition: all 0.2s ease-in-out;

    color: #fff;
}

#Scenarios img.img-fluid {
    -webkit-box-sizing: border-box;

    box-sizing: border-box;

    width: 100%;

    outline: #000 solid 6px;

    outline-offset: -6px;
}

#Scenarios .image-info-container img {
    width: 5vw;
    height: 5vw;
    margin: 2%;
}

#Scenarios .image-info-container h3 {
    font-family: "Raleway", sans-serif;

    line-height: 1.5vw;

    font-weight: 900;

    margin: 0% 0% 0% -1.2%;
}

@media screen and (max-width: 768px) {
}

@media (max-width: 767px) {
    #Scenarios .image-info-container h3 {
        text-align: left;

        line-height: 4.55vw;

        font-size: 5.3vw;

        font-weight: 900;
    }
}

@media (max-width: 767px) {
    #Scenarios .image-info-container img {
        width: 80px;

        height: 20vw;
    }

    #Scenarios .col-12 {
        padding: 0;
    }
}

@media (min-width: 768px) {
    p {
        font-size: 18px;

        font-weight: 100;

        line-height: 1.3;

        margin: 0 0 35px;
    }
}

#mainNav {
    font-family: "Raleway", sans-serif;
    margin-bottom: 0;
    text-transform: capitalize;
    background-color: #000;
    font-weight: 500;
    background: #000000;
    align-items: stretch;
}

@media (max-width: 600px) {
    #mainNav {
        top: 0.8em;
        position: fixed;
        width: auto;
        text-align: right;
        z-index: 1;
    }
}

#mainNav .navbar-toggler {
    font-size: 14px;
    color: #fff;
    outline: 0;
}

#mainNav a {
    color: #fff;
}

#mainNav a:hover {
    color: #da1a35;
}

#mainNav .navbar-nav .nav-item:hover {
    color: fade(white, 80%);

    outline: 0;

    background-color: transparent;
}

#mainNav .navbar-nav .nav-item:active,
#mainNav .navbar-nav .nav-item:focus {
    outline: 0;

    background-color: transparent;
}

@media (min-width: 992px) {
    #mainNav {
        padding: 15px 5%;

        -webkit-transition: background 0.3s ease-in-out,
            padding-top 0.3s ease-in-out, padding-bottom 0.3s;

        -moz-transition: background 0.3s ease-in-out,
            padding-top 0.3s ease-in-out, padding-bottom 0.3s;

        transition: background 0.3s ease-in-out, padding-top 0.3s ease-in-out,
            padding-bottom 0.3s;

        letter-spacing: 1px;

        border-bottom: none;
    }

    #mainNav .nav-link.active {
        outline: 0;
    }

    #mainNav .nav-link.active:hover {
        color: #fff;
    }

    .nav-link {
        padding: 0;
    }

    div#navbarResponsive a {
        position: relative;
    }

    div#navbarResponsive a:hover {
        color: #fff;
    }

    div#navbarResponsive a:before {
        content: "";

        position: absolute;

        width: 95%;

        height: 2px;

        bottom: -2px;

        left: 2%;

        background-color: #da1a35;

        visibility: hidden;

        -webkit-transform: scaleX(0);

        transform: scaleX(0);

        -webkit-transition: all 0.15s ease-in-out 0s;

        transition: all 0.15s ease-in-out 0s;
    }

    li#navbarDropdownMenuLink a:before {
        background-color: transparent;
    }

    li#navbarDropdownMenuLink {
        text-transform: uppercase;
    }

    div#navbarResponsive a.active:before,
    div#navbarResponsive a:hover:before {
        visibility: visible;

        -webkit-transform: scaleX(1);

        transform: scaleX(1);
    }

    .active {
        color: #da1a35;
    }

    .counter {
        font-size: 18px;

        color: #da1a35;

        font-weight: 300;

        margin-bottom: 10px;

        display: inline-block;
    }
}
@media (min-width: 1900px) {
    .counter {
        font-size: 35px;

        color: #da1a35;

        font-weight: 300;

        margin-bottom: 10px;

        display: inline-block;
    }
}

body {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0.2);
}
