.menu-button-container {
    display: none;
    height: 100%;
    width: 30px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

#vertical-logo {
    width: 65px;
    position: fixed;
    left: 5%;
    top: 2%;
}

#menu-toggle {
    display: none;
}

.menu-button,
.menu-button::before,
.menu-button::after {
    top: 2.8em;
    display: block;
    background-color: #fff;
    position: fixed;
    height: 4px;
    width: 30px;
    transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1);
    border-radius: 2px;
    z-index: 1;
}

.menu-button {
    width: 18px;
}
.menu-button::before {
    width: 18px;
    content: "";
    margin-top: 1em;
}

.menu-button::after {
    width: 18px;
    content: "";
    margin-top: 0.5em;
}

#menu-toggle:checked + .menu-button-container .menu-button::before {
    margin-top: 0px;
    transform: rotate(405deg);
}

#menu-toggle:checked + .menu-button-container .menu-button {
    background: rgba(255, 255, 255, 0);
}

#menu-toggle:checked + .menu-button-container .menu-button::after {
    margin-top: 0px;
    transform: rotate(-405deg);
}

.menu-button-container {
    display: flex;
}
.menu {
    position: fixed;
    margin-top: 7em;
    width: 100%;
    right: inherit;
    top: inherit;
}

#menu-toggle ~ .menu li {
    display: none;
}
#menu-toggle:checked ~ .menu li {
    text-align: right;
    display: inline-block;
    flex-direction: column;
    background: 0;
    height: 2.5em;
    padding: 0em 5em 2em 1em;
    transition: height 400ms cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 1030;
}

.menu > li {
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    color: white;
    background-color: #222;
}

#nav-item {
    color: #ffffff;
}

div#navbarResponsive {
    text-align: right;
}

@media (min-width: 992px) {
    .nav-link {
        padding: 0;
    }

    div#navbarResponsive a {
        position: relative;
    }

    div#navbarResponsive a:hover {
        color: #fff;
    }

    div#navbarResponsive a:before {
        content: "";
        position: absolute;
        width: 95%;
        height: 2px;
        bottom: -2px;
        left: 2%;
        background-color: #da1a35;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.15s ease-in-out 0s;
        transition: all 0.15s ease-in-out 0s;
    }

    li#navbarDropdownMenuLink a:before {
        background-color: transparent;
    }

    div#navbarResponsive a.active:before,
    div#navbarResponsive a:hover:before {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
}

#nav-item:hover {
    color: #ffffff;
    outline: 0;
    background-color: transparent;
}

@media (max-width: 992px) {
    #vertical-logo {
        width: 50px;
        left: 10%;
        top: 2%;
    }

    #menu-toggle:checked ~ .menu li {
        padding: 0em 2em 2em 1em;
    }
    .menu-button,
    .menu-button::before,
    .menu-button::after {
        top: 3.5%;
    }
    #nav-item {
        font-size: 0.7rem;
    }
    #vertical-mobile-lang-links {
        margin-left: 3%;
    }
}

@media (min-width: 992px) and (max-width: 1200px) {
    .menu-button {
        right: 6rem;
    }
}

@media (max-width: 601px) {
    #menu_btn {
        right: 1.5rem;
    }
    #menu-toggle:checked ~ .menu li:last-child {
        padding: 0em 3em 2em 1em;
    }
    .menu {
        margin-top: 6em;
    }
}

@media (min-width: 1200px) {
    #menu_btn {
        right: 6rem;
    }
}

@media (min-width: 992px) {
    #vertical-mobile-lang-links {
        margin-left: 1.5%;
    }
    .vertical-btn {
        margin-left: 1.5%;
    }
    #menu-btn {
        right: 7%;
    }
    #menu-toggle:checked ~ .menu li {
        padding: 0em 7em 2em 1em;
    }
}
