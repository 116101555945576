.facebook-link {
    margin-right: 1%;
}

.instagram-link {
    margin-left: 1%;
}

.follow {
    box-sizing: border-box;
    color: #da1a35;
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    padding-top: 1%;
}

#social {
    padding: 5px !important;
}
