@keyframes fadeAnimation {
    0% {
        opacity: 0;
        transform: translateY(-10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated-nav {
    animation: fadeAnimation 0.5s;
}

@keyframes closeFadeAnimation {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.closing-menu {
    animation: closeFadeAnimation 0.5s;
}

@media (max-width: 500px) {
    #nav-links {
        margin-right: 3%;
    }
}

@media (min-width: 500px) and (max-width: 767px) {
    #nav-links {
        margin-right: 5%;
    }
}

@media (min-width: 767px) and (max-width: 991px) {
    #nav-links {
        margin-right: 6.5%;
    }
}
