@import url("https://fonts.googleapis.com/css2?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Raleway");

body {
    background-color: #0e0e0e;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #000000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #1a1a1a;
}

body::-webkit-scrollbar {
    width: 0;
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

body::-webkit-scrollbar-track {
    display: transparent;
}

body::-webkit-scrollbar-thumb {
    background: transparent;
}

body::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
